exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-form-step-2-tsx": () => import("./../../../src/pages/form/step2.tsx" /* webpackChunkName: "component---src-pages-form-step-2-tsx" */),
  "component---src-pages-form-thanks-tsx": () => import("./../../../src/pages/form/thanks.tsx" /* webpackChunkName: "component---src-pages-form-thanks-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jak-sprzedac-instrument-tsx": () => import("./../../../src/pages/jak-sprzedac-instrument.tsx" /* webpackChunkName: "component---src-pages-jak-sprzedac-instrument-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-offer-contract-tsx": () => import("./../../../src/pages/offer/contract.tsx" /* webpackChunkName: "component---src-pages-offer-contract-tsx" */),
  "component---src-pages-skup-instrumentow-detych-tsx": () => import("./../../../src/pages/skup-instrumentow-detych.tsx" /* webpackChunkName: "component---src-pages-skup-instrumentow-detych-tsx" */),
  "slice---src-components-footer-tsx": () => import("./../../../src/components/footer.tsx" /* webpackChunkName: "slice---src-components-footer-tsx" */),
  "slice---src-components-header-tsx": () => import("./../../../src/components/header.tsx" /* webpackChunkName: "slice---src-components-header-tsx" */)
}

